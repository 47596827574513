import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';

import { useIntl, LoadingSpinner, Masonry, NativeSlider } from '@ttstr/components';
import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const WunderbareWelt: React.FC = () => {
  const { t } = useTranslation();
  const { products, loading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article className="my-5">
      <Helmet>
        <body className="ww-page product-listing-page inverse-navbar" />
      </Helmet>

      <Container>
        <Row className="mt-5 mb-5">
          <Col md="12">
            <h2 className="h2 mb-0 d-block text-center">FATONI</h2>
            <h1 className="text-center">
              WUNDERBARE WELT
              <br />
            </h1>
          </Col>
        </Row>
        {/* <NativeSlider
          className="mb-0 pt-md-5 pb-md-5"
          images={[
            require('./assets/images/header-lesereise.jpg'),
            require('./assets/images/Kachel-4-ALGMerch.png'),
            require('./assets/images/Kachel-3-DDMerch.png'),
            require('./assets/images/Kachel-2-ALGTickets.png'),
            require('./assets/images/Kachel-1-DDTickets.png'),
          ]}
          // keine echten thumbs, bilder werden gestaucht
          // thumbs={[require('./assets/images/slide1.jpg'), require('./assets/images/slide2.jpg')]}
          links={['/lesereise', '/products', '/dangerdan', '/ALGtickets', '/DDtickets']}
          interval={5000}
        /> */}
      </Container>

      <Container className="">
        {loading ? (
          // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : // if no products
        products.length === 0 ? (
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((p) => p.tag_ids.includes(6164))}
            filters="off"
            showFormerPrice
            showTags
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(WunderbareWelt);
